import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { TemplateProgramFilterValues } from '@/features/program-management/components/TemplateProgramFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '../utils';
import {
  GetTemplatePrograms,
  type GetTemplateProgramsQueryVariables
} from './graphql/GetTemplatePrograms.gql';

type QueryVariables = Pick<GetTemplateProgramsQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<TemplateProgramFilterValues>;
};

export function getTemplateProgramsQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: programManagementKeys.templateProgramsList(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetTemplatePrograms, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'TITLE_ASC',
  filter: {}
};

export function templateProgramsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getTemplateProgramsQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetTemplateProgramsQueryVariables {
  const {
    filter: { creators, searchText },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetTemplateProgramsQueryVariables['filter']> = {};

  if (creators?.length) {
    filter.creatorIds = creators.map(c => c.id);
  }

  const _searchText = searchText?.trim();

  if (_searchText) {
    filter.searchText = _searchText;
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
