import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, staticRoleAssignmentKeys } from '../utils';
import { GetAddStaticScopeRoleAssignmentFormData } from './graphql/GetAddStaticScopeRoleAssignmentFormData.gql';

export function getAddStaticScopeRoleAssignmentFormDataQueryOptions() {
  return queryOptions({
    queryKey: staticRoleAssignmentKeys.staticRoleAssignment_add(),
    queryFn: () => fetchDocument(GetAddStaticScopeRoleAssignmentFormData),
    staleTime: LOADER_STALE_TIME
  });
}

export function addStaticScopeRoleAssignmentFormDataLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getAddStaticScopeRoleAssignmentFormDataQueryOptions());
}
