import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '@/app/routes/utils';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { GetEditTemplateProgramFormData } from './graphql/GetEditTemplateProgramFormData.gql';

export function getEditTemplateProgramFormDataQueryOptions(params: Params<'templateProgramId'>) {
  return queryOptions({
    queryKey: programManagementKeys.templateProgram_edit(getRouteParam(params.templateProgramId)),
    queryFn: ({ queryKey: [{ templateProgramId }] }) =>
      fetchDocument(GetEditTemplateProgramFormData, { templateProgramId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function editTemplateProgramFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getEditTemplateProgramFormDataQueryOptions(params));
}
