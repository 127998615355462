import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '../utils';
import { GetProgramManagementData } from './graphql/GetProgramManagementData.gql';

export function getProgramManagementDataQueryOptions(params: Params<'programId'>) {
  return queryOptions({
    queryKey: programManagementKeys.program(getRouteParam(params.programId)),
    queryFn: ({ queryKey: [{ programId }] }) =>
      fetchDocument(GetProgramManagementData, { programId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function programManagementDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getProgramManagementDataQueryOptions(params));
}
