import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetEditWebhookFormDataQueryVariables = Types.Exact<{
  webhookId: Types.Scalars['GUID']['input'];
}>;


export type GetEditWebhookFormDataQuery = { webhook: { __typename: 'Webhook', callbackUrl: Types.Scalars['String']['output'], events: Array<Types.WebhookEventType>, id: Types.Scalars['GUID']['output'], name: Types.Scalars['String']['output'], verificationToken: Types.Scalars['String']['output'] } | null };


export const GetEditWebhookFormData = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEditWebhookFormData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"webhookId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"webhook"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"webhookId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"callbackUrl"}},{"kind":"Field","name":{"kind":"Name","value":"events"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"verificationToken"}}]}}]}}]} as unknown as DocumentNode<GetEditWebhookFormDataQuery, GetEditWebhookFormDataQueryVariables>;