import {
  type DependencyList,
  type EffectCallback,
  useEffect,
  useLayoutEffect,
  useRef
} from 'react';

/**
 * Taken from https://github.com/chancestrickland/hooks/blob/master/src/use-update-effect.ts
 */

export function useUpdateEffect(effect: EffectCallback, deps?: DependencyList) {
  const mounted = useRef(false);
  const effectRef = useRef(effect);

  useLayoutEffect(() => {
    effectRef.current = effect;
  }, [effect]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (mounted.current) return effectRef.current();
    mounted.current = true;
  }, deps); // eslint-disable-line react-hooks/exhaustive-deps
}
