import { ideationModuleRequestKeys, LOADER_STALE_TIME, queryOptions } from '@/app/routes/utils';
import { fetchDocument } from '@/utils/api/fetcher';
import { parseFile } from '@/utils/api/parsers';
import type { IdeationModuleRequestData } from '../IdeationModuleRequestView';
import {
  GetIdeationModuleRequestData,
  type GetIdeationModuleRequestDataQuery
} from './GetIdeationModuleRequestData.gql';

export function getIdeationModuleRequestDataQueryOptions(params: {
  programId: string;
  requestId: string;
}) {
  return queryOptions({
    queryKey: ideationModuleRequestKeys.request(params.programId, params.requestId),
    queryFn: ({ queryKey: [{ programId, requestId }] }) =>
      fetchDocument(GetIdeationModuleRequestData, { programId, requestId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function parseIdeationModuleRequest(
  data: GetIdeationModuleRequestDataQuery | undefined,
  programId: string
): IdeationModuleRequestData | null | undefined {
  const request = data?.ideationModuleRequest;

  if (!request) return request;

  return {
    attachments: request.attachments.map(parseFile),
    contributionTemplate: {
      id: request.contributionTemplate.id,
      name: request.contributionTemplate.name
    },
    createdModule: request.createdModule,
    creator: request.creator,
    dateCreated: request.dateCreated,
    dateEvaluated: request.dateEvaluated,
    description: request.description,
    evaluationReason: request.evaluationReason,
    evaluator: request.evaluator,
    id: request.id,
    image: request.image,
    programId,
    scheduledEndDate: request.scheduledEndDate,
    scheduledStartDate: request.scheduledStartDate,
    status: request.status,
    summary: request.summary,
    title: request.title
  };
}
