import {
  type ReactNode,
  useContext,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { useAppConfigValue } from '@/components/AppConfig';
import { ErrorBoundary } from '@/components/ErrorBoundary';
import { FocusWrapper, GuidedFocusContext, useFocusHandler } from '@/components/FocusManagement';
import { useI18n } from '@/components/I18n';
import { hasNonEmptyStringValue } from '@/utils/common';
import { useLogger } from '@/utils/hooks/useLogger';

function getFormattedTitle(appName: string, title: string, contentTitle?: string | null) {
  return [contentTitle, title, appName].filter(hasNonEmptyStringValue).join(' – ');
}

type Props = {
  children?: ReactNode;
  // delayFocusUntilHeadingVisible?: boolean;
  /**
   * The ID of the page. This needs to be completely unique, meaning that, if
   * dealing with an entity, the id of the entity should be included as well.
   * @example <Page id={`contribution-${id}`} {...} />
   */
  id: string;
  /**
   * The title that will be incorporated in the document title and be part of
   * the screen reader announcement.
   */
  title: string;
} & (
  | { getContentTitle: () => Promise<string | null | undefined>; contentTitle?: never }
  | { getContentTitle?: never; contentTitle: string | undefined }
  | { getContentTitle?: never; contentTitle?: never }
);

type FocusValue = {
  id: string;
  isReady: boolean;
};

function shouldFocusAfterValueChange(value: FocusValue, prevValue: FocusValue | null) {
  return (
    // We're on a new page that is immediately ready
    (value.isReady && value.id !== prevValue?.id) ||
    // ... or we stayed on the same page which was now marked ready
    (value.isReady && !prevValue?.isReady)
  );
}

export const Page = ({ children, contentTitle, getContentTitle, id, title }: Props) => {
  const i18n = useI18n();
  const { announcePageNavigation } = useContext(GuidedFocusContext);
  const appName = useAppConfigValue('application_applicationName');
  const logger = useLogger('Page');
  const [pageTitle, setPageTitle] = useState<string | null>(null);
  const [isReady, setIsReady] = useState(false);
  const isMountedRef = useRef(true);

  /**
   * The screen reader behavior you would normally want is that it first
   * announces the page navigation and only then the newly focused container
   * since that is the flow on a classic website. Especially since the navigation
   * announcement is marked "assertive" and will interrupt everything else being
   * read out. However, a screen reader also gives "assertive" priority to a
   * focus() event, and if the navigation is announced before the focus event it
   * will be interrupted midway. No bueno, since the navigation announcement is
   * more important than the heading or nav label being read out.
   * There's not really a way around this as far as I can tell, so instead we
   * have to invert the order and first guide the focus to the heading/nav after
   * which we can announce the navigation. This way the navigation annoucement
   * will interrupt the focus announcement before it has a chance to start,
   * resulting in the navigation being the only thing communicated to the user.
   * This is fine, since the focus announcement will usually be a heading that
   * contains the same text as the page title, while the navigation announcement
   * contains the "Navigated to" text which is crucial for a screen reader user
   * to find their bearings after clicking something that navigates to a
   * different page.
   * We can achieve this with some trickery involving the useFocusHandler(). By
   * passing it an object value ({ id, isReady }) together with a custom
   * compare function, we can force the handler to hold off on focusing until
   * the `isReady` has been toggled to true. If we would just pass the "id"
   * field, useFocusHandler would immediately trigger while the page title is
   * still being fetched.
   * Initially this was implemented with a <RoutedFocusHandler> component, but
   * the approach below is less complicated and also not react-router dependent.
   */
  const focusRef = useFocusHandler(
    id,
    useMemo(() => ({ id, isReady }), [id, isReady]),
    shouldFocusAfterValueChange,
    {
      lookupNestedTarget: true,
      scrollBehavior: 'scrollToTop'
    }
  );

  useLayoutEffect(() => {
    setIsReady(false);
  }, []);

  useEffect(() => {
    if (pageTitle) {
      document.title = pageTitle;
      // We call the "announce" method every time the page title changes. This
      // does not mean that it will be announced multiple times in case of a
      // dynamic title (query), as the GuidedFocusManager makes sure that only
      // one announcement is made per location. Every focus request is on a
      // 250ms delay, which means that we have a 250ms window to update our
      // title before it is announced for realsies.
      announcePageNavigation(pageTitle);
    }
  }, [announcePageNavigation, pageTitle]);

  useEffect(() => {
    if (!getContentTitle) {
      // We're dealing with a static title, set it and mark the page as ready.
      setPageTitle(getFormattedTitle(appName, title, contentTitle));
      setIsReady(true);
    } else {
      // We're dealing with a dynamic title. Set up a "loading" placeholder and
      // load the dynamic behavior.
      setPageTitle(getFormattedTitle(appName, title, i18n.t('common', 'loading.text')));
      setIsReady(false);
      getContentTitle()
        .then(titleResponse => {
          if (!isMountedRef.current) return;
          // We received our dynamic title, set it and mark the page as ready.
          // If we're lucky we received it before the announcement was made and
          // it's still read out to the screen reader.
          setPageTitle(getFormattedTitle(appName, title, titleResponse));
          setIsReady(true);
        })
        .catch((err: unknown) => {
          // Something went wrong while getting the dynamic title. Default to
          // a simple title instead and mark the page as ready.
          setPageTitle(getFormattedTitle(appName, title));
          setIsReady(true);
          logger('error', 'Error getting content title', err);
        });
    }
  }, [appName, contentTitle, getContentTitle, i18n, logger, title]);

  useEffect(
    () => () => {
      isMountedRef.current = false;
    },
    []
  );

  return (
    <FocusWrapper ref={focusRef} debugName={`page-${id}`}>
      <ErrorBoundary>{children}</ErrorBoundary>
    </FocusWrapper>
  );
};
