import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, webhookKeys } from '../utils';
import { GetWebhooks } from './graphql/GetWebhooks.gql';

export function getWebhooksQueryOptions() {
  return queryOptions({
    queryKey: webhookKeys.list_all(),
    queryFn: () => fetchDocument(GetWebhooks),
    staleTime: LOADER_STALE_TIME
  });
}

export function webhooksLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getWebhooksQueryOptions());
}
