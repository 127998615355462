import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, profileCompletionSettingsKeys, queryOptions } from '../utils';
import { GetProfileCompletionSettings } from './graphql/GetProfileCompletionSettings.gql';

export function getProfileCompletionSettingsQueryOptions() {
  return queryOptions({
    queryKey: profileCompletionSettingsKeys.all,
    queryFn: () => fetchDocument(GetProfileCompletionSettings),
    staleTime: LOADER_STALE_TIME
  });
}

export function ProfileCompletionSettingsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getProfileCompletionSettingsQueryOptions());
}
