import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '../utils';
import { GetProgramRoleAssignments } from './graphql/GetProgramRoleAssignments.gql';

export function getProgramRoleAssignmentsQueryOptions(params: Params<'programId'>) {
  return queryOptions({
    queryKey: programManagementKeys.programRoleAssignmentsList(getRouteParam(params.programId)),
    queryFn: ({ queryKey: [{ programId }] }) =>
      fetchDocument(GetProgramRoleAssignments, { programId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function programRoleAssignmentsLoader(client: QueryClient): LoaderFunction {
  return ({ params }: { params: Params<'programId'> }) =>
    client.ensureQueryData(getProgramRoleAssignmentsQueryOptions(params));
}
