import { css } from '@emotion/react';
import { getColorToken, getToken } from '@/css/utils';

// This is hardcoded inside <NavLink> and added by default. Exporting it from
// here rather than the component in order to avoid a cyclic dependency error.
const ACTIVE_CLASS_NAME = 'active';

export const link = css`
  align-items: center;
  border-bottom: 2px solid transparent;
  border-top: 2px solid transparent;
  color: ${getColorToken('textLight')};
  display: flex;
  flex-direction: column;
  font-size: ${getToken('fontSizes', 'md')};
  font-weight: ${getToken('fontWeights', 'normal')};
  justify-content: center;
  margin-bottom: -1px;
  margin-left: 12px;
  margin-right: 12px;
  text-align: center;
  text-decoration: none;
  transition:
    color 150ms ease-in-out,
    border-color 150ms ease-in-out,
    font-weight 150ms ease-out;

  ::after {
    content: attr(data-text);
    font-weight: ${getToken('fontWeights', 'semibold')};
    height: 0;
    overflow: hidden;
    visibility: hidden;
  }

  &:hover {
    font-weight: ${getToken('fontWeights', 'semibold')};
    color: ${getColorToken('textDark')};
    text-decoration: none;
  }
`;

const activeStyle = css`
  font-weight: ${getToken('fontWeights', 'semibold')};
  color: ${getColorToken('textDark')};
  border-bottom-color: ${getColorToken('primary')};
`;

export const activeLink = css(link, activeStyle);

export const menuLink = css`
  ${link};

  &.${ACTIVE_CLASS_NAME} {
    ${activeStyle};
  }
`;
