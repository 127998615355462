import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { dashboardSettingsKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetDashboardSettings } from './graphql/GetDashboardSettings.gql';

export function getDashboardSettingsQueryOptions() {
  return queryOptions({
    queryKey: dashboardSettingsKeys.all,
    queryFn: () => fetchDocument(GetDashboardSettings),
    staleTime: LOADER_STALE_TIME
  });
}

export function DashboardSettingsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getDashboardSettingsQueryOptions());
}
