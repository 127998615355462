import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { UserRemovalRequestFilterValues } from '@/features/data-management/components/UserRemovalRequestFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, userRemovalRequestKeys } from '../utils';
import {
  GetUserRemovalRequests,
  type GetUserRemovalRequestsQueryVariables
} from './graphql/GetUserRemovalRequests.gql';

type QueryVariables = Pick<GetUserRemovalRequestsQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<UserRemovalRequestFilterValues>;
};

export function getUserRemovalRequestsQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: userRemovalRequestKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetUserRemovalRequests, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'DATE_REQUESTED_DESC',
  filter: {}
};

export function userRemovalRequestsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getUserRemovalRequestsQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetUserRemovalRequestsQueryVariables {
  const {
    filter: { requestStatuses, searchText },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetUserRemovalRequestsQueryVariables['filter']> = {};

  if (requestStatuses?.length) {
    filter.states = requestStatuses;
  }

  const _searchText = searchText?.trim();

  if (_searchText) {
    filter.searchText = _searchText;
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
