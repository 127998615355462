import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, staticRoleAssignmentKeys } from '../utils';
import { GetStaticRoleAssignments } from './graphql/GetStaticRoleAssignments.gql';

export function getStaticRoleAssignmentsQueryOptions() {
  return queryOptions({
    queryKey: staticRoleAssignmentKeys.list_all(),
    queryFn: () => fetchDocument(GetStaticRoleAssignments),
    staleTime: LOADER_STALE_TIME
  });
}

export function staticRoleAssignmentsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getStaticRoleAssignmentsQueryOptions());
}
