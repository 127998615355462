import { createElement } from 'react';
import { render } from 'react-dom';
import { RouterProvider } from 'react-router-dom';
import { type AppConfig, useAppConfigStore } from '@/components/AppConfig';
import { AppError } from '@/components/AppError';
import { AuthenticationClient } from '@/libs/cs-core-auth-client';
import { createI18nClient } from '@/utils/createI18nClient';
import { loadAppConfig } from '@/utils/loadAppConfig';
import { get, set } from '@/utils/store';
import { join } from '@/utils/url';
import { createRouter } from './app/router';
import { isSupportedLanguage } from './components/I18n/utils';
import { setupGoogleAnalytics } from './utils/analytics';
import { hasNonEmptyStringValue } from './utils/common';
import { API_INFO_URL, DEFAULT_USER_LANGUAGE, IS_DEBUG } from './constants';
import { install as installPolyfills } from './polyfills';
import { redirectActionFragment, redirectLegacyRoutes } from './redirects';
import 'normalize.css/normalize.css';
import '@/fonts/overpass/stylesheet.css';
import './css/global.css';

// if (process.env.NODE_ENV === 'development') {
//   (await import('./testing/IntersectionObserverDebugger')).installIntersectionObserverDebugger();
// }

redirectLegacyRoutes();
redirectActionFragment();

if (window.__CSP_NONCE__) {
  __webpack_nonce__ = window.__CSP_NONCE__;
}

const MOUNT_NODE = document.getElementById('root');

// eslint-disable-next-line no-console
const log = (...args: unknown[]) => console.log('[Init]', ...args);

async function loadConfig(): Promise<AppConfig> {
  log('Loading remote configuration...');

  try {
    const config = await loadAppConfig(API_INFO_URL, IS_DEBUG);

    log('Remote configuration loaded.', config);

    return config;
  } catch (e) {
    log('Error loading remote configuration:', e);
    throw e;
  }
}

function getUserLocale(config: AppConfig): string {
  const locales = [
    config.viewer_localeId, // Prioritize any locale set for the user on the server
    get('localeId'), // ... if none found, use the locale from localStorage
    ...navigator.languages, // ... if none found, look for a supported locale in the browser locales
    config.application_defaultLocaleId // ... if none found, use the default application locale
  ].filter(hasNonEmptyStringValue);

  let userLocale = DEFAULT_USER_LANGUAGE;

  // Find the first supported language.
  for (let i = 0; i < locales.length; i += 1) {
    const locale = locales[i].toLowerCase();

    if (isSupportedLanguage(locale)) {
      userLocale = locale;
      break;
    }

    if (locale.includes('-')) {
      // We're dealing with a regional variant, check if we can get a match on
      // the first part instead (i.e, "en" in "en-gb").
      const language = locale.split('-')[0];

      if (isSupportedLanguage(language)) {
        userLocale = language;
        break;
      }
    }
  }

  return userLocale;
}

async function start() {
  const startTime = new Date();

  try {
    const [config] = await Promise.all([loadConfig(), installPolyfills()]);
    const trackingId = config.application_branding_analyticsTrackingId;

    if (trackingId?.startsWith('GTM-') || trackingId?.startsWith('G-')) {
      setupGoogleAnalytics(trackingId, startTime);
    }

    useAppConfigStore.setState({ config });

    const i18nClient = await createI18nClient(getUserLocale(config));

    // Caching for later in case the user gets signed out.
    if (config.viewer_localeId) {
      set('localeId', config.viewer_localeId);
    }

    const loginUrl = join(config.application_url, config.auth_login_endpoint);
    const logoutUrl = join(config.application_url, config.auth_logout_endpoint);
    const authClient = new AuthenticationClient({ loginUrl, logoutUrl });

    render(
      createElement(RouterProvider, {
        router: createRouter(authClient, i18nClient, IS_DEBUG)
      }),
      MOUNT_NODE
    );
  } catch (error) {
    if (process.env.NODE_ENV === 'development') {
      console.error(error); // eslint-disable-line no-console
    }

    if (error instanceof Error) {
      render(createElement(AppError, { error }), MOUNT_NODE);
    }

    setTimeout(start, 2500);
  }
}

start();
