import {
  type DefinitionNode,
  type DocumentNode,
  type FieldNode,
  Kind,
  type OperationDefinitionNode,
  type OperationTypeNode,
  print,
  visit
} from '@0no-co/graphql.web';
import type { TypedDocumentNode } from '@graphql-typed-document-node/core';
import { useAppConfigStore } from '@/components/AppConfig';
import { CSRF_HEADER } from '@/constants';
import { AuthenticationClient } from '@/libs/cs-core-auth-client';
import { isObject } from '../common';
import { getCsrfToken } from '../csrf';
import { UserNotAuthorizedError } from '../errors';
import { join } from '../url';
import { RequestError } from './error';

const TYPENAME_FIELD: FieldNode = {
  kind: Kind.FIELD,
  name: { kind: Kind.NAME, value: '__typename' }
};

// Mostly copied from https://github.com/apollographql/apollo-client/blob/9c5a8cee40900125fe5037d573e6daedd619075f/src/utilities/graphql/transform.ts#L453
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function addTypenameToDocument<TData, TVars>(
  doc: TypedDocumentNode<TData, TVars>
): TypedDocumentNode<TData, TVars> {
  return visit(doc, {
    SelectionSet: {
      enter(node, _key, parent) {
        // Don't add __typename to OperationDefinitions.
        if (parent && (parent as OperationDefinitionNode).kind === Kind.OPERATION_DEFINITION) {
          return;
        }

        // No changes if no selections.
        const { selections } = node;
        if (!selections) {
          return;
        }

        // If selections already have a __typename, or are part of an
        // introspection query, do nothing.
        const skip = selections.some(
          selection =>
            selection.kind === Kind.FIELD &&
            (selection.name.value === '__typename' ||
              selection.name.value.lastIndexOf('__', 0) === 0)
        );
        if (skip) {
          return;
        }

        // If this SelectionSet is @export-ed as an input variable, it should
        // not have a __typename field (see issue #4691).
        const field = parent as FieldNode;
        if (
          field.kind === Kind.FIELD &&
          field.directives &&
          field.directives.some(d => d.name.value === 'export')
        ) {
          return;
        }

        // Create and return a new SelectionSet with a __typename Field.
        // eslint-disable-next-line consistent-return
        return { ...node, selections: [...selections, TYPENAME_FIELD] };
      }
    }
  });
}

type OperationDefinitionWithName = OperationDefinitionNode & {
  name: NonNullable<OperationDefinitionNode['name']>;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function getOperationName<TData, TVars>(doc: TypedDocumentNode<TData, TVars>): string | null {
  return (
    doc.definitions
      .filter(
        (def): def is OperationDefinitionWithName =>
          def.kind === 'OperationDefinition' && !!def.name
      )
      .map(x => x.name.value)[0] || null
  );
}

function isOperationDefinitionNode(node: DefinitionNode): node is OperationDefinitionNode {
  return node.kind === 'OperationDefinition';
}

function getOperationType(document: DocumentNode): OperationTypeNode | undefined {
  return document.definitions.slice(0).reverse().find(isOperationDefinitionNode)?.operation;
}

type EmptyVariables = Api.Exact<{ [key: string]: never }>;

// See https://github.com/graphql/graphql-over-http/blob/a1e6d8ca248c9a19eb59a2eedd988c204909ee3f/spec/GraphQLOverHTTP.md
const ACCEPT_HEADER =
  'application/graphql-response+json; charset=utf-8, application/json; charset=utf-8';

export function fetchDocument<TData, TVariables>(
  document: TypedDocumentNode<TData, TVariables>,
  ...[variables]: TVariables extends EmptyVariables ? [] : [TVariables]
) {
  return fetcher<TData, TVariables, typeof variables>(document, variables);
}

async function fetcher<
  TData,
  TVariables,
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  TInputVariables = TVariables extends EmptyVariables ? void : TVariables
>(document: TypedDocumentNode<TData, TVariables>, variables: TInputVariables): Promise<TData> {
  const { config } = useAppConfigStore.getState();
  const url = join(config.application_url, config.graphql_endpoint);
  const csrfToken = getCsrfToken();
  const headers = csrfToken ? { [CSRF_HEADER]: csrfToken } : undefined;

  let response: Response;

  try {
    response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        Accept: ACCEPT_HEADER,
        'Content-Type': 'application/json',
        ...headers
      },
      body: JSON.stringify({
        // Usage of addTypenameToDocument() is a temporary workaround for the
        // discrepancies between apollo's document objects + always injected
        // typename fields and react-query's stringified documents + opt-in
        // typename fields. The codegen is currently set up for apollo which
        // means we need to inject typenames for react-query ourselves, since
        // some code relies on the presence of a __typename field.
        // See also PR #793 which unfortunately did not provide a full fix since
        // the codegen appears to occasionally skip fragments and/or unions.
        query: print(addTypenameToDocument(document)),
        variables,
        operationName: getOperationName(document)
      })
    });
  } catch (err) {
    const _err = err instanceof Error ? err : new Error('Error sending request.');

    throw new RequestError({ networkError: _err });
  }

  let networkError: Error | undefined;

  if (!response?.ok) {
    networkError = new Error(`${response.status} ${response.statusText}`);

    if (response.status === 401) {
      // eslint-disable-next-line no-console
      console.log('[Fetcher] Received a 401 response, redirecting to login.');

      // TODO: Do this differently, but preferably without passing down an auth
      // client instance. Perhaps the usefulness of AuthenticationClient itself
      // needs to be re-evaluated.
      const loginUrl = join(config.application_url, config.auth_login_endpoint);
      const logoutUrl = join(config.application_url, config.auth_logout_endpoint);
      const authClient = new AuthenticationClient({ loginUrl, logoutUrl });
      authClient.login();
    } else if (response.status === 403) {
      let json: unknown;

      try {
        json = await response.json();

        if (
          isObject(json) &&
          typeof json.message === 'string' &&
          json.message.match(/CSRF Token Mismatch/i)
        ) {
          // eslint-disable-next-line no-console
          console.log('[Fetcher] CSRF token no longer valid, refreshing the page.');
          window.location.reload();
        }
      } catch (err) {
        // Do nothing, error will be handled below.
      }
    }
  }

  let json: unknown;

  try {
    json = await response.json();
  } catch (err) {
    throw new RequestError({
      networkError: networkError ?? new Error('Error parsing response.'),
      response
    });
  }

  if (typeof json !== 'object' || json === null) {
    throw new RequestError({
      networkError: new Error('Received malformed response.'),
      response
    });
  }

  if ('errors' in json && Array.isArray(json.errors) && json.errors.length > 0) {
    const error = new RequestError({ graphQLErrors: json.errors, networkError, response });
    const authError = error.graphQLErrors.find(
      err => err.extensions?.code === 'USER_AGREEMENT_GUARD'
    );

    if (authError) {
      throw new RequestError({
        response,
        networkError: new UserNotAuthorizedError(
          typeof authError.extensions.subjectId === 'string'
            ? authError.extensions.subjectId
            : null,
          getOperationName(document),
          getOperationType(document) || null
        )
      });
    }

    throw error;
  }

  if (!('data' in json)) {
    throw new RequestError({
      networkError: new Error('Received empty response.'),
      response
    });
  }

  return json.data as TData;
}
