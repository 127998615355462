import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { apiClientKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetEditApiClientFormData } from './graphql/GetEditApiClientFormData.gql';

export const getEditApiClientFormDataQueryOptions = (params: Params<'apiClientId'>) =>
  queryOptions({
    queryKey: apiClientKeys.apiClientById_edit(getRouteParam(params.apiClientId)),
    queryFn: ({ queryKey: [{ apiClientId }] }) => fetchDocument(GetEditApiClientFormData, { apiClientId }), // prettier-ignore
    staleTime: LOADER_STALE_TIME
  });

export function editApiClientFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getEditApiClientFormDataQueryOptions(params));
}
