import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, sliceTemplateKeys } from '../utils';
import { GetSliceTemplateData } from './graphql/GetSliceTemplateData.gql';

export function getSliceTemplateDataQueryOptions(params: Params<'sliceTemplateId'>) {
  return queryOptions({
    queryKey: sliceTemplateKeys.sliceTemplateById(getRouteParam(params.sliceTemplateId)),
    queryFn: ({ queryKey: [{ sliceTemplateId }] }) =>
      fetchDocument(GetSliceTemplateData, { sliceTemplateId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function sliceTemplateDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getSliceTemplateDataQueryOptions(params));
}
