import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { ProgramFilterValues } from '@/features/program-management/components/ProgramFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '../utils';
import { GetPrograms, type GetProgramsQueryVariables } from './graphql/GetPrograms.gql';

type QueryVariables = Pick<GetProgramsQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<ProgramFilterValues>;
};

export function getProgramsQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: programManagementKeys.programsList(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetPrograms, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'TITLE_ASC',
  filter: {}
};

export function programsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getProgramsQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetProgramsQueryVariables {
  const {
    filter: { creators, communities, modes, statuses, searchText },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetProgramsQueryVariables['filter']> = {};

  if (statuses?.length === 1) {
    if (statuses.includes('INACTIVE')) {
      filter.isActive = false;
    } else if (statuses.includes('ACTIVE')) {
      filter.isActive = true;
    }
  }

  if (modes?.length === 1) {
    if (modes.includes('UNPUBLISHED')) {
      filter.isPublished = false;
    } else if (modes.includes('PUBLISHED')) {
      filter.isPublished = true;
    }
  }

  if (communities?.length) {
    filter.communityIds = communities.map(c => c.id);
  }

  if (creators?.length) {
    filter.creatorIds = creators.map(c => c.id);
  }

  const _searchText = searchText?.trim();

  if (_searchText) {
    filter.searchText = _searchText;
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
