import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetEditDispatcherFormDataQueryVariables = Types.Exact<{
  moduleId: Types.Scalars['GUID']['input'];
}>;


export type GetEditDispatcherFormDataQuery = { module: { __typename: 'ArchiveModule', id: Types.Scalars['GUID']['output'], programId: Types.Scalars['GUID']['output'], stageId: Types.Scalars['GUID']['output'], dispatcher: { __typename: 'Dispatcher', dispatchIntervalInDays: number, type: Types.DispatcherType } | null } | { __typename: 'EnrichmentModule', id: Types.Scalars['GUID']['output'], programId: Types.Scalars['GUID']['output'], stageId: Types.Scalars['GUID']['output'], dispatcher: { __typename: 'Dispatcher', dispatchIntervalInDays: number, type: Types.DispatcherType } | null } | { __typename: 'IdeationModule', id: Types.Scalars['GUID']['output'], programId: Types.Scalars['GUID']['output'], stageId: Types.Scalars['GUID']['output'], dispatcher: { __typename: 'Dispatcher', dispatchIntervalInDays: number, type: Types.DispatcherType } | null } | { __typename: 'ReviewModule', id: Types.Scalars['GUID']['output'], programId: Types.Scalars['GUID']['output'], stageId: Types.Scalars['GUID']['output'], dispatcher: { __typename: 'Dispatcher', dispatchIntervalInDays: number, type: Types.DispatcherType } | null } | null };


export const GetEditDispatcherFormData = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEditDispatcherFormData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"moduleId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"module"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"moduleId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"InlineFragment","typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"IBaseModule"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"programId"}},{"kind":"Field","name":{"kind":"Name","value":"stageId"}},{"kind":"Field","name":{"kind":"Name","value":"dispatcher"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"dispatchIntervalInDays"}},{"kind":"Field","name":{"kind":"Name","value":"type"}}]}}]}}]}}]}}]} as unknown as DocumentNode<GetEditDispatcherFormDataQuery, GetEditDispatcherFormDataQueryVariables>;