import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { getEditDispatcherFormDataQueryOptions } from '@/features/dispatcher/views/DispatcherSettingsFormModal/queries';
import { getRouteParam } from '@/utils/routing';

export function editTemplateProgramModuleDispatcherFormDataLoader(
  client: QueryClient
): LoaderFunction {
  return ({ params }: { params: Params<'moduleId' | 'templateProgramId'> }) =>
    client.ensureQueryData(
      getEditDispatcherFormDataQueryOptions({ moduleId: getRouteParam(params.moduleId) })
    );
}
