import { type ReactNode, useEffect } from 'react';
import { useToggle } from '@/utils/hooks/useToggle';

type Props = {
  children: ReactNode;
  delayInMs: number;
};

export const Delay = ({ children, delayInMs }: Props) => {
  const [value, { on }] = useToggle();

  useEffect(() => {
    const timer = window.setTimeout(on, delayInMs);
    return () => window.clearTimeout(timer);
  }, [delayInMs, on]);

  return value ? <>{children}</> : null;
};
