import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type WebhookFragment = { __typename: 'Webhook', callbackUrl: Types.Scalars['String']['output'], events: Array<Types.WebhookEventType>, id: Types.Scalars['GUID']['output'], name: Types.Scalars['String']['output'], verificationToken: Types.Scalars['String']['output'] };

export type GetWebhooksQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type GetWebhooksQuery = { webhooks: Array<{ __typename: 'Webhook', id: Types.Scalars['GUID']['output'], callbackUrl: Types.Scalars['String']['output'], events: Array<Types.WebhookEventType>, name: Types.Scalars['String']['output'], verificationToken: Types.Scalars['String']['output'] }>, sessionUser: { __typename: 'SessionUser', id: Types.Scalars['GUID']['output'], canManageWebhooks: boolean } | null };

export const WebhookFragmentDoc = {"kind":"Document","definitions":[{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WebhookFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Webhook"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"callbackUrl"}},{"kind":"Field","name":{"kind":"Name","value":"events"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"verificationToken"}}]}}]} as unknown as DocumentNode<WebhookFragment, unknown>;
export const GetWebhooks = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetWebhooks"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"webhooks"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"webHookSortOrder"},"value":{"kind":"EnumValue","value":"NAME_ASC"}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"WebhookFragment"}}]}},{"kind":"Field","name":{"kind":"Name","value":"sessionUser"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"canManageWebhooks"},"name":{"kind":"Name","value":"hasRootPermissionTo"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"access"},"value":{"kind":"EnumValue","value":"MANAGE"}},{"kind":"Argument","name":{"kind":"Name","value":"objectType"},"value":{"kind":"EnumValue","value":"WEBHOOK"}}]},{"kind":"Field","name":{"kind":"Name","value":"id"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"WebhookFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"Webhook"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"callbackUrl"}},{"kind":"Field","name":{"kind":"Name","value":"events"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"verificationToken"}}]}}]} as unknown as DocumentNode<GetWebhooksQuery, GetWebhooksQueryVariables>;