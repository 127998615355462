import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { fieldTypeKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetEditFieldTypeFormData } from './graphql/GetEditFieldTypeFormData.gql';

export const getEditFieldTypeFormDataQueryOptions = (params: Params<'fieldTypeId'>) =>
  queryOptions({
    queryKey: fieldTypeKeys.fieldTypeById_edit(getRouteParam(params.fieldTypeId)),
    queryFn: ({ queryKey: [{ fieldTypeId }] }) => fetchDocument(GetEditFieldTypeFormData, { fieldTypeId }), // prettier-ignore
    staleTime: LOADER_STALE_TIME
  });

export function editFieldTypeFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getEditFieldTypeFormDataQueryOptions(params));
}
