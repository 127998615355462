import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetAddProgramRoleAssignmentFormDataQueryVariables = Types.Exact<{
  programId: Types.Scalars['GUID']['input'];
}>;


export type GetAddProgramRoleAssignmentFormDataQuery = { program: { __typename: 'Program', id: Types.Scalars['GUID']['output'], unusedRoles: Array<{ __typename: 'Role', id: Types.Scalars['GUID']['output'], name: Types.Scalars['String']['output'], permissionCount: number }> } | null };


export const GetAddProgramRoleAssignmentFormData = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetAddProgramRoleAssignmentFormData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"programId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"program"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"programId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"unusedRoles"},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"permissionCount"}}]}}]}}]}}]} as unknown as DocumentNode<GetAddProgramRoleAssignmentFormDataQuery, GetAddProgramRoleAssignmentFormDataQueryVariables>;