import { useMemo } from 'react';
import { getBoolean } from '@/utils/store';
import { isOldEdge } from '@/utils/userAgent';

type LogTypeMap = {
  info: Parameters<typeof console.info>;
  log: Parameters<typeof console.log>;
  warn: Parameters<typeof console.warn>;
  error: Parameters<typeof console.error>;
  time: Parameters<typeof console.time>;
  timeEnd: Parameters<typeof console.timeEnd>;
};

export type LogType = keyof LogTypeMap;

export type LogFunction = (type: LogType, ...args: LogTypeMap[LogType]) => void;

// IE/Non-Chromium Edge does not supported (color) substitutions so we're
// setting up a fallback for those browsers.
const getPrefix: (prefix: string) => string[] = isOldEdge()
  ? prefix => [`${prefix}:`]
  : prefix => [`%c${prefix}:`, 'font-weight: bold'];

function log<T extends LogType>(prefix: string, ...rest: [type: T, ...args: LogTypeMap[T]]): void {
  const [type, ...args] = rest;

  switch (type) {
    case 'info':
      console.info(...getPrefix(prefix), ...args); // eslint-disable-line no-console
      break;
    case 'warn':
      console.warn(...getPrefix(prefix), ...args); // eslint-disable-line no-console
      break;
    case 'error':
      console.error(...getPrefix(prefix), ...args); // eslint-disable-line no-console
      break;
    case 'time':
      console.time(...args); // eslint-disable-line no-console
      break;
    case 'timeEnd':
      console.timeEnd(...args); // eslint-disable-line no-console
      break;
    case 'log':
    default:
      console.log(...getPrefix(prefix), ...args); // eslint-disable-line no-console
  }
}

const noop = () => {};

export function useLogger(
  prefix: string,
  isEnabled: boolean = getBoolean('isDebugEnabled')
): LogFunction {
  return useMemo(
    () =>
      isEnabled
        ? (type: LogType, ...args: LogTypeMap[LogType]) => log(prefix, type, ...args)
        : noop,
    [isEnabled, prefix]
  );
}
