import { useCallback, useMemo, useState } from 'react';

type ToggleHookActions = {
  on: () => void;
  off: () => void;
  toggle: () => void;
};

type ToggleHookReturnValue = [value: boolean, actions: ToggleHookActions];

export function useToggle(initialValue = false): ToggleHookReturnValue {
  const [value, setValue] = useState(initialValue);
  const on = useCallback(() => setValue(true), []);
  const off = useCallback(() => setValue(false), []);
  const toggle = useCallback(() => setValue(v => !v), []);

  const actions = useMemo(() => ({ on, off, toggle }), [on, off, toggle]);
  return useMemo(() => [value, actions], [value, actions]);
}
