import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, roleKeys } from '../utils';
import { GetRoleData } from './graphql/GetRoleData.gql';

export function getRoleDataQueryOptions(params: Params<'roleId'>) {
  return queryOptions({
    queryKey: roleKeys.roleById(getRouteParam(params.roleId)),
    queryFn: ({ queryKey: [{ roleId }] }) => fetchDocument(GetRoleData, { roleId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function roleDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getRoleDataQueryOptions(params));
}
