import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { UserImportFilterValues } from '@/features/user-import/components/UserImportFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, userImportKeys } from '../utils';
import { GetUserImports, type GetUserImportsQueryVariables } from './graphql/GetUserImports.gql';

type QueryVariables = Pick<GetUserImportsQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<UserImportFilterValues>;
};

export function getUserImportsQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: userImportKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetUserImports, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'STATE',
  filter: {}
};

export function userImportsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getUserImportsQueryOptions(defaultVariables));
}

export function parseVariables(variables: QueryVariables): GetUserImportsQueryVariables {
  const {
    filter: { userImportStatuses },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetUserImportsQueryVariables['filter']> = {};

  // If all 5 are included we're omitting the filter since it serves no purpose.
  if (userImportStatuses?.length && userImportStatuses.length < 5) {
    filter.states = userImportStatuses;
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
