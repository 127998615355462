export type JargonMap = Omit<Api.Jargon, '__typename'>;

type LazyJargonLookupMap<T extends keyof JargonMap> = Record<JargonMap[T], () => string>;

export function lookupJargonTranslation<T extends keyof JargonMap>(
  term: JargonMap[T],
  map: LazyJargonLookupMap<T>
) {
  return map[term]();
}
