/* eslint-disable no-console */

import { createContext, useContext } from 'react';
import mitt, { type Emitter, type EventHandlerMap } from 'mitt';

type Events = {
  contributionAdded: string;
  contributionDeleted: string;
  draftDeleted: string;
  draftPublished: string;
  draftUpdated: string;
  ideationModuleRequestAdded: { requestId: string };
  ideationModuleRequestUpdated: { programId: string; requestId: string };
  jargonChanged: undefined;
  reviewModuleSliceTemplateUpdated: { moduleId: string; sliceTemplateId: string };
  userAgreementSubmissionCompleted: undefined;
  weightedReviewScoreStatusUpdated: { moduleId: string; sliceTemplateId: string };
};

const handlers: EventHandlerMap<Events> = new Map();

if (process.env.NODE_ENV === 'development') {
  /* eslint-disable no-console */ handlers.set('*', [
    (type, e) => console.log(`[EventEmitter:${type.toString()}]`, e)
  ]);
}

// Only exported for testing purposes.
export const __emitter_instance = mitt(handlers);

const EventEmitterContext = createContext(__emitter_instance);

export const useEventEmitter = (): Emitter<Events> => useContext(EventEmitterContext);
