import { LOADER_STALE_TIME, moduleKeys, queryOptions } from '@/app/routes/utils';
import { fetchDocument } from '@/utils/api/fetcher';
import { GetEditDispatcherFormData } from './graphql/GetEditDispatcherFormData.gql';

export const getEditDispatcherFormDataQueryOptions = (params: { moduleId: string }) =>
  queryOptions({
    queryKey: moduleKeys.dispatcher_edit(params.moduleId),
    queryFn: ({ queryKey: [{ moduleId }] }) =>
      fetchDocument(GetEditDispatcherFormData, { moduleId }),
    staleTime: LOADER_STALE_TIME
  });
