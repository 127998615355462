import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, localeSettingsKeys, queryOptions } from '../utils';
import { GetLocaleSettings } from './graphql/GetLocaleSettings.gql';

export function getLocaleSettingsQueryOptions() {
  return queryOptions({
    queryKey: localeSettingsKeys.all,
    queryFn: () => fetchDocument(GetLocaleSettings),
    staleTime: LOADER_STALE_TIME
  });
}

export function LocaleSettingsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getLocaleSettingsQueryOptions());
}
