import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { jargonKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetJargonData } from './graphql/GetJargonData.gql';

export function getJargonDataQueryOptions() {
  return queryOptions({
    queryKey: jargonKeys.all,
    queryFn: () => fetchDocument(GetJargonData),
    staleTime: LOADER_STALE_TIME
  });
}

export function JargonDataLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getJargonDataQueryOptions());
}
