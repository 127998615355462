import type * as Types from '@/typings/api.generated.d';

import type { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';
export type GetEditApiClientFormDataQueryVariables = Types.Exact<{
  apiClientId: Types.Scalars['GUID']['input'];
}>;


export type GetEditApiClientFormDataQuery = { apiClient: { __typename: 'OAuth2Application', id: Types.Scalars['GUID']['output'], applicationUrl: Types.Scalars['URI']['output'], description: Types.Scalars['String']['output'] | null, clientId: Types.Scalars['String']['output'], clientSecret: Types.Scalars['String']['output'], name: Types.Scalars['String']['output'], redirectUrls: Array<Types.Scalars['URI']['output']>, supportedFlows: Array<Types.OAuth2Flow> } | null };


export const GetEditApiClientFormData = {"kind":"Document","definitions":[{"kind":"OperationDefinition","operation":"query","name":{"kind":"Name","value":"GetEditApiClientFormData"},"variableDefinitions":[{"kind":"VariableDefinition","variable":{"kind":"Variable","name":{"kind":"Name","value":"apiClientId"}},"type":{"kind":"NonNullType","type":{"kind":"NamedType","name":{"kind":"Name","value":"GUID"}}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","alias":{"kind":"Name","value":"apiClient"},"name":{"kind":"Name","value":"oAuth2Application"},"arguments":[{"kind":"Argument","name":{"kind":"Name","value":"id"},"value":{"kind":"Variable","name":{"kind":"Name","value":"apiClientId"}}}],"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"FragmentSpread","name":{"kind":"Name","value":"BaseApiClientFragment"}}]}}]}},{"kind":"FragmentDefinition","name":{"kind":"Name","value":"BaseApiClientFragment"},"typeCondition":{"kind":"NamedType","name":{"kind":"Name","value":"OAuth2Application"}},"selectionSet":{"kind":"SelectionSet","selections":[{"kind":"Field","name":{"kind":"Name","value":"applicationUrl"}},{"kind":"Field","name":{"kind":"Name","value":"description"}},{"kind":"Field","name":{"kind":"Name","value":"clientId"}},{"kind":"Field","name":{"kind":"Name","value":"clientSecret"}},{"kind":"Field","name":{"kind":"Name","value":"id"}},{"kind":"Field","name":{"kind":"Name","value":"name"}},{"kind":"Field","name":{"kind":"Name","value":"redirectUrls"}},{"kind":"Field","name":{"kind":"Name","value":"supportedFlows"}}]}}]} as unknown as DocumentNode<GetEditApiClientFormDataQuery, GetEditApiClientFormDataQueryVariables>;