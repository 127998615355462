import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { apiClientKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetApiClients } from './graphql/GetApiClients.gql';

export function getApiClientsQueryOptions() {
  return queryOptions({
    queryKey: apiClientKeys.list_all(),
    queryFn: () => fetchDocument(GetApiClients),
    staleTime: LOADER_STALE_TIME
  });
}

export function apiClientsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getApiClientsQueryOptions());
}
