import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, queryOptions, userClaimKeys } from '../utils';
import { GetEditIdentityProviderFormData } from './graphql/GetEditIdentityProviderFormData.gql';

export const getEditIdentityProviderFormDataQueryOptions = (params: Params<'identityProviderId'>) =>
  queryOptions({
    queryKey: userClaimKeys.identityProviderById_edit(getRouteParam(params.identityProviderId)),
    queryFn: ({ queryKey: [{ identityProviderId }] }) =>
      fetchDocument(GetEditIdentityProviderFormData, { identityProviderId }),
    staleTime: LOADER_STALE_TIME
  });

export function editIdentityProviderFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) =>
    client.ensureQueryData(getEditIdentityProviderFormDataQueryOptions(params));
}
