// import { findAndReplaceLiteralVideoAnchors } from './videoEmbed';
import { useContext, useEffect, useMemo, useRef } from 'react';
import { createPath, type To } from 'react-router-dom';
import anchorme from 'anchorme';
import { getNextHeadingLevel, HeadingContext, type HeadingLevel } from '@/components/Heading';
import { Box } from '@/components/primitives';
import { useSharedLocations } from '@/utils/hooks/useSharedLocations';
import { isNumber } from '@/utils/number';
import './RichTextView.css';

type Props = {
  content: string | null | undefined;
};

function replaceMentionSpansWithAnchors(
  container: HTMLDivElement,
  getProfileRoute: (id: string) => To
) {
  const mentionNodes = container.querySelectorAll<HTMLElement>('[data-mention-user-id]');

  mentionNodes.forEach(node => {
    const { attributes: attrs, parentNode } = node;
    const userId = node.dataset.mentionUserId;

    if (userId) {
      const anchorNode = document.createElement('a');

      for (let i = 0, l = attrs.length; i < l; i += 1) {
        const attr = attrs.item(i);

        if (attr?.nodeValue) {
          anchorNode.setAttribute(attr.nodeName, attr.nodeValue);
        }
      }

      const route = getProfileRoute(userId);

      anchorNode.innerHTML = node.innerHTML;
      anchorNode.setAttribute('href', typeof route === 'string' ? route : createPath(route));
      anchorNode.setAttribute('target', '_blank');

      if (parentNode) {
        parentNode.replaceChild(anchorNode, node);
      }
    }
  });
}

function rebaseHeadings(container: HTMLDivElement, baseLevel: HeadingLevel) {
  container.querySelectorAll('h1,h2,h3,h4,h5,h6').forEach(h => {
    const level = parseInt(h.tagName.substring(1, 2), 10);
    const ariaLevel = parseInt(h.getAttribute('aria-level') || level.toString(), 10);
    const wantedLevel = Math.min(6, level + baseLevel - 1); // Don't exceed 6.

    if (isNumber(wantedLevel) && ariaLevel !== wantedLevel) {
      h.setAttribute('aria-level', wantedLevel.toString());
    }
  });
}

export const RichTextView = ({ content }: Props) => {
  const ref = useRef<HTMLDivElement>(null);
  const headingLevel = useContext(HeadingContext);
  const { getUserProfilePage } = useSharedLocations('main');

  const html = useMemo(
    () =>
      anchorme(content || '', {
        attributes: [{ name: 'target', value: '_blank' }],
        truncate: [40, 20]
      }),
    [content]
  );

  useEffect(() => {
    if (ref.current) {
      replaceMentionSpansWithAnchors(ref.current, getUserProfilePage);
      // findAndReplaceLiteralVideoAnchors(ref.current);
      rebaseHeadings(ref.current, getNextHeadingLevel(headingLevel));
    }
  }, [getUserProfilePage, headingLevel]);

  return (
    <Box
      ref={ref}
      className="fr-view"
      dangerouslySetInnerHTML={{ __html: html }}
      dir="auto"
      overflowWrap="break-word"
    />
  );
};
