import { createBrowserRouter, Navigate } from 'react-router-dom';
import { QueryClient } from '@tanstack/react-query';
import type { i18n } from 'i18next';
import { AuthenticationGuard } from '@/components/AuthenticationGuard';
import { RouteErrorAlert } from '@/components/ErrorAlert/RouteErrorAlert';
import type { AuthenticationClient } from '@/libs/cs-core-auth-client';
import { DashboardPage } from '@/pages/main/DashboardPage';
import { NotFoundPage } from '@/pages/NotFoundPage';
import { userImportsLoader } from './routes/admin._user-management.user-imports/queries';
import { userInviteLinkLoader } from './routes/admin._user-management.user-invite-links.$inviteLinkId._index/queries';
import { userInviteLinkUsersLoader } from './routes/admin._user-management.user-invite-links.$inviteLinkId.users/queries';
import { userInviteLinksLoader } from './routes/admin._user-management.user-invite-links/queries';
import { userRemovalRequestLoader } from './routes/admin._user-management.user-removal-requests.$requestId/queries';
import { userRemovalRequestsLoader } from './routes/admin._user-management.user-removal-requests/queries';
import { editUserFormDataLoader } from './routes/admin._user-management.users.$userId.edit/queries';
import { usersLoader } from './routes/admin._user-management.users/queries';
import { communitiesLoader } from './routes/admin.communities/queries';
import { communityDataLoader } from './routes/admin.communities_.$communityId/queries';
import { editFieldTypeFormDataLoader } from './routes/admin.field-types.$fieldTypeId.edit/queries';
import { fieldTypesLoader } from './routes/admin.field-types/queries';
import { BrandingSettingsLoader } from './routes/admin.general-settings.$/queries';
import { DashboardSettingsLoader } from './routes/admin.general-settings.dashboard/queries';
import { JargonDataLoader } from './routes/admin.general-settings.jargon/queries';
import { LocaleSettingsLoader } from './routes/admin.general-settings.language/queries';
import { ProfileCompletionSettingsLoader } from './routes/admin.general-settings.profile-completion/queries';
import { webhooksLoader } from './routes/admin.integrations.$/queries';
import { editApiClientFormDataLoader } from './routes/admin.integrations.api-clients.$apiClientId.edit/queries';
import { apiClientsLoader } from './routes/admin.integrations.api-clients/queries';
import { editWebhookFormDataLoader } from './routes/admin.integrations.webhooks.$webhookId.edit/queries';
import { templateProgramsLoader } from './routes/admin.program-templates/queries';
import { editTemplateProgramFormDataLoader } from './routes/admin.program-templates_.$templateProgramId.edit/queries';
import { editTemplateProgramModuleDispatcherFormDataLoader } from './routes/admin.program-templates_.$templateProgramId.modules.$moduleId.dispatcher/queries';
import { templateProgramManagementDataLoader } from './routes/admin.program-templates_.$templateProgramId/queries';
import { programsLoader } from './routes/admin.programs/queries';
import { programIdeationModuleRequestDataLoader } from './routes/admin.programs_.$programId.$.challenge-requests.$requestId/queries';
import { editProgramModuleDispatcherFormDataLoader } from './routes/admin.programs_.$programId.$.modules.$moduleId.dispatcher/queries';
import { programStagesManagementDataLoader } from './routes/admin.programs_.$programId.$/queries';
import { addProgramRoleAssignmentFormDataLoader } from './routes/admin.programs_.$programId.role-assignments.new/queries';
import { programRoleAssignmentsLoader } from './routes/admin.programs_.$programId.role-assignments/queries';
import { programManagementDataLoader } from './routes/admin.programs_.$programId/queries';
import { addStaticScopeRoleAssignmentFormDataLoader } from './routes/admin.role-assignments.new/queries';
import { staticRoleAssignmentsLoader } from './routes/admin.role-assignments/queries';
import { rolesLoader } from './routes/admin.roles/queries';
import { roleDataLoader } from './routes/admin.roles_.$roleId/queries';
import { sliceTemplatesLoader } from './routes/admin.slice-templates/queries';
import { sliceTemplateDataLoader } from './routes/admin.slice-templates_.$sliceTemplateId/queries';
import { editUserClaimFormDataLoader } from './routes/admin.user-claims.$userClaimId.edit/queries';
import { editIdentityProviderFormDataLoader } from './routes/admin.user-claims.identity-providers.$identityProviderId.edit/queries';
import { userClaimSettingsLoader } from './routes/admin.user-claims/queries';
import { editUserGroupFormDataLoader } from './routes/admin.user-groups.$userGroupId.edit/queries';
import { userGroupsLoader } from './routes/admin.user-groups/queries';
import { userRegistrationSettingsLoader } from './routes/admin.user-registration/queries';
import { AppRoot } from './AppRoot';
import { AuthenticatedRoot } from './AuthenticatedRoot';
import { Root } from './Root';

export function createRouter(authClient: AuthenticationClient, i18nClient: i18n, debug: boolean) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
        retry: false
      }
    }
  });

  return createBrowserRouter([
    {
      path: '*',
      /** <Root> wraps the tree with an i18n & theming provider */
      element: <Root i18nClient={i18nClient} />,
      children: [
        {
          path: 'status/*',
          errorElement: <RouteErrorAlert />,
          lazy: async () => ({
            Component: (await import('@/pages/StatusPage')).StatusPage
          })
        },
        {
          path: 'invite/:invitationToken',
          errorElement: <RouteErrorAlert />,
          lazy: async () => ({
            Component: (await import('@/pages/AcceptInvitationPage')).AcceptInvitationPage
          })
        },
        {
          /**
           * <AuthenticatedRoot> wraps the tree with an Apollo, File API & DateFns
           * provider. Additionally, it short-circuits the tree if any pending global user
           * agreements are present.
           */
          element: (
            <AuthenticationGuard authenticationClient={authClient}>
              <AuthenticatedRoot authClient={authClient} debug={debug} queryClient={queryClient} />
            </AuthenticationGuard>
          ),
          errorElement: <RouteErrorAlert />,
          children: [
            {
              /**
               * <AppRoot> fetches the session user, renders the app shell, sets up focus
               * management, triggers an onboarding flow if necessary and mounts globally available
               * modals.
               */
              element: <AppRoot logoutUrl={authClient.config.logoutUrl} />,
              children: [
                { index: true, element: <DashboardPage /> },
                { path: 'feed', element: <DashboardPage activeView="feed" /> },
                { path: 'programs', element: <DashboardPage activeView="programs" /> },
                { path: 'tasks', element: <DashboardPage activeView="tasks" /> },
                { path: '*', element: <NotFoundPage /> },
                {
                  path: 'communities/:communityId',
                  children: [
                    { index: true, element: <DashboardPage /> },
                    { path: 'feed', element: <DashboardPage activeView="feed" /> },
                    { path: 'programs', element: <DashboardPage activeView="programs" /> },
                    { path: 'tasks', element: <DashboardPage activeView="tasks" /> }
                  ]
                },
                {
                  path: 'contributions/:contributionId/*',
                  lazy: () => import('./routes/contributions.$contributionId/route')
                },
                {
                  path: 'modules/:moduleId',
                  lazy: () => import('./routes/modules.$moduleId/route')
                },
                {
                  path: 'programs/:programId/*',
                  lazy: () => import('./routes/programs.$programId/route')
                },
                {
                  path: 'search',
                  lazy: () => import('./routes/search/route')
                },
                {
                  path: 'profiles',
                  children: [
                    {
                      path: 'me/*',
                      lazy: () => import('./routes/profiles.me/route')
                    },
                    {
                      path: ':userId/*',
                      lazy: () => import('./routes/profiles.$userId/route')
                    }
                  ]
                },
                {
                  path: 'tasks',
                  children: [
                    {
                      path: 'enrichments/:moduleId/:contributionId?',
                      lazy: () => import('./routes/tasks.enrichments.$moduleId.($contributionId)/route') // prettier-ignore
                    },
                    {
                      path: 'reviews/:moduleId/:contributionId?',
                      lazy: () => import('./routes/tasks.reviews.$moduleId.($contributionId)/route')
                    }
                  ]
                },
                {
                  path: 'reports',
                  children: [
                    {
                      index: true,
                      lazy: () => import('./routes/reports._index/route')
                    },
                    {
                      path: ':reportId',
                      lazy: () => import('./routes/reports.$reportId/route')
                    }
                  ]
                },
                {
                  path: 'challenge-requests/*',
                  lazy: () => import('./routes/challenge-requests/route')
                },
                {
                  errorElement: <RouteErrorAlert />,
                  lazy: async () => ({
                    Component: (await import('@/features/configuration/containers/ConfigurationGuardContainer')).ConfigurationGuardContainer // prettier-ignore
                  }),
                  children: [
                    {
                      path: 'admin',
                      errorElement: <RouteErrorAlert />,
                      children: [
                        {
                          index: true,
                          element: <Navigate replace to="users" />
                        },
                        /** Field type routes. */
                        {
                          path: 'field-types',
                          loader: fieldTypesLoader(queryClient),
                          lazy: () => import('./routes/admin.field-types/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.field-types.new/route')
                            },
                            {
                              path: ':fieldTypeId/edit',
                              loader: editFieldTypeFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.field-types.$fieldTypeId.edit/route') // prettier-ignore
                            }
                          ]
                        },
                        /** Community routes. */
                        {
                          path: 'communities',
                          lazy: () => import('./routes/admin.communities/route'),
                          loader: communitiesLoader(queryClient),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.communities.new/route')
                            }
                          ]
                        },
                        {
                          path: 'communities/:communityId',
                          loader: communityDataLoader(queryClient),
                          lazy: () => import('./routes/admin.communities_.$communityId/route'),
                          children: [
                            {
                              path: '*',
                              lazy: () => import('./routes/admin.communities_.$communityId.$/route'), // prettier-ignore
                              children: [
                                {
                                  path: 'programs/new',
                                  lazy: () => import('./routes/admin.communities_.$communityId.$.programs.new/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'role-assignments',
                              lazy: () => import('./routes/admin.communities_.$communityId.role-assignments/route') // prettier-ignore
                            },
                            {
                              path: 'user-agreements/*',
                              lazy: () => import('./routes/admin.communities_.$communityId.user-agreements/route') // prettier-ignore
                            }
                          ]
                        },
                        /** Data management routes. */
                        {
                          path: 'data-management',
                          lazy: () => import('./routes/admin.data-management/route')
                        },
                        /** General settings routes. */
                        {
                          path: 'general-settings',
                          lazy: () => import('./routes/admin.general-settings/route'),
                          children: [
                            {
                              path: '*',
                              loader: BrandingSettingsLoader(queryClient),
                              lazy: () => import('./routes/admin.general-settings.$/route')
                            },
                            {
                              path: 'dashboard',
                              loader: DashboardSettingsLoader(queryClient),
                              lazy: () => import('./routes/admin.general-settings.dashboard/route')
                            },
                            {
                              path: 'profile-completion',
                              loader: ProfileCompletionSettingsLoader(queryClient),
                              lazy: () => import('./routes/admin.general-settings.profile-completion/route') // prettier-ignore
                            },
                            {
                              path: 'language',
                              loader: LocaleSettingsLoader(queryClient),
                              lazy: () => import('./routes/admin.general-settings.language/route')
                            },
                            {
                              path: 'jargon',
                              loader: JargonDataLoader(queryClient),
                              lazy: () => import('./routes/admin.general-settings.jargon/route')
                            }
                          ]
                        },
                        /** User registration settings routes. */
                        {
                          path: 'user-registration',
                          loader: userRegistrationSettingsLoader(queryClient),
                          lazy: () => import('./routes/admin.user-registration/route')
                        },
                        /** User agreement routes. */ {
                          path: 'user-agreements/*',
                          lazy: () => import('./routes/admin.user-agreements/route')
                        },
                        /** User claim routes. */
                        {
                          path: 'user-claims',
                          loader: userClaimSettingsLoader(queryClient),
                          lazy: () => import('./routes/admin.user-claims/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.user-claims.new/route')
                            },
                            {
                              path: ':userClaimId/edit',
                              loader: editUserClaimFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.user-claims.$userClaimId.edit/route') // prettier-ignore
                            },
                            {
                              path: 'identity-providers/:identityProviderId/edit',
                              loader: editIdentityProviderFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.user-claims.identity-providers.$identityProviderId.edit/route') // prettier-ignore
                            }
                          ]
                        },
                        /** Integrations routes. */
                        {
                          path: 'integrations',
                          lazy: () => import('./routes/admin.integrations/route'),
                          children: [
                            {
                              path: '*',
                              loader: webhooksLoader(queryClient),
                              lazy: () => import('./routes/admin.integrations.$/route'),
                              children: [
                                {
                                  path: 'webhooks/new',
                                  lazy: () => import('./routes/admin.integrations.webhooks.new/route') // prettier-ignore
                                },
                                {
                                  path: 'webhooks/:webhookId/edit',
                                  loader: editWebhookFormDataLoader(queryClient),
                                  lazy: () => import('./routes/admin.integrations.webhooks.$webhookId.edit/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'api-clients',
                              loader: apiClientsLoader(queryClient),
                              lazy: () => import('./routes/admin.integrations.api-clients/route'),
                              children: [
                                {
                                  path: 'new',
                                  lazy: () => import('./routes/admin.integrations.api-clients.new/route') // prettier-ignore
                                },
                                {
                                  path: ':apiClientId/edit',
                                  loader: editApiClientFormDataLoader(queryClient),
                                  lazy: () => import('./routes/admin.integrations.api-clients.$apiClientId.edit/route') // prettier-ignore
                                }
                              ]
                            }
                          ]
                        },
                        /** Role & role assignment routes. */
                        {
                          path: 'role-assignments',
                          loader: staticRoleAssignmentsLoader(queryClient),
                          lazy: () => import('./routes/admin.role-assignments/route'),
                          children: [
                            {
                              path: 'new',
                              loader: addStaticScopeRoleAssignmentFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.role-assignments.new/route')
                            }
                          ]
                        },
                        {
                          path: 'roles',
                          loader: rolesLoader(queryClient),
                          lazy: () => import('./routes/admin.roles/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.roles.new/route')
                            }
                          ]
                        },
                        {
                          path: 'roles/:roleId',
                          loader: roleDataLoader(queryClient),
                          lazy: () => import('./routes/admin.roles_.$roleId/route')
                        },
                        /** Report routes. */
                        {
                          path: 'reports',
                          children: [
                            {
                              path: 'data-sets',
                              lazy: () => import('./routes/admin.reports.data-sets/route')
                            },
                            {
                              path: 'kpis',
                              lazy: () => import('./routes/admin.reports.kpis/route')
                            }
                          ]
                        },
                        /** Slice template routes. */
                        {
                          path: 'slice-templates',
                          lazy: () => import('./routes/admin.slice-templates/route'),
                          loader: sliceTemplatesLoader(queryClient),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.slice-templates.new/route')
                            }
                          ]
                        },
                        {
                          path: 'slice-templates/:sliceTemplateId',
                          loader: sliceTemplateDataLoader(queryClient),
                          lazy: () => import('./routes/admin.slice-templates_.$sliceTemplateId/route'), // prettier-ignore
                          children: [
                            {
                              path: '*',
                              lazy: () => import('./routes/admin.slice-templates_.$sliceTemplateId.$/route') // prettier-ignore
                            },
                            {
                              path: 'weighted-review-scores/*',
                              lazy: () => import('./routes/admin.slice-templates_.$sliceTemplateId.weighted-review-scores/route') // prettier-ignore
                            }
                          ]
                        },
                        /** User management routes. */
                        {
                          lazy: () => import('./routes/admin._user-management/route'),
                          children: [
                            {
                              path: 'users',
                              loader: usersLoader(queryClient),
                              lazy: () => import('./routes/admin._user-management.users/route'),
                              children: [
                                {
                                  path: ':userId/edit',
                                  loader: editUserFormDataLoader(queryClient),
                                  lazy: () => import('./routes/admin._user-management.users.$userId.edit/route') // prettier-ignore
                                },
                                {
                                  path: 'new',
                                  lazy: () => import('./routes/admin._user-management.users.new/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'user-invite-links',
                              loader: userInviteLinksLoader(queryClient),
                              lazy: () => import('./routes/admin._user-management.user-invite-links/route'), // prettier-ignore
                              children: [
                                {
                                  path: ':inviteLinkId',
                                  loader: userInviteLinkLoader(queryClient),
                                  lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId/route'), // prettier-ignore
                                  children: [
                                    {
                                      index: true,
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId._index/route') // prettier-ignore
                                    },
                                    {
                                      path: 'edit',
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId.edit/route') // prettier-ignore
                                    },
                                    {
                                      path: 'logs',
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId.logs/route') // prettier-ignore
                                    },
                                    {
                                      path: 'users',
                                      loader: userInviteLinkUsersLoader(queryClient),
                                      lazy: () => import('./routes/admin._user-management.user-invite-links.$inviteLinkId.users/route') // prettier-ignore
                                    }
                                  ]
                                },
                                {
                                  path: 'new',
                                  lazy: () =>
                                    import(
                                      './routes/admin._user-management.user-invite-links.new/route'
                                    )
                                }
                              ]
                            },
                            {
                              path: 'user-imports',
                              loader: userImportsLoader(queryClient),
                              lazy: () =>
                                import('./routes/admin._user-management.user-imports/route'),
                              children: [
                                {
                                  path: 'new',
                                  lazy: () => import('./routes/admin._user-management.user-imports.new/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'user-removal-requests',
                              loader: userRemovalRequestsLoader(queryClient),
                              lazy: () =>
                                import(
                                  './routes/admin._user-management.user-removal-requests/route'
                                ),
                              children: [
                                {
                                  path: ':requestId',
                                  loader: userRemovalRequestLoader(queryClient),
                                  lazy: () => import('./routes/admin._user-management.user-removal-requests.$requestId/route') // prettier-ignore
                                },
                                {
                                  path: 'new',
                                  lazy: () => import('./routes/admin._user-management.user-removal-requests.new/route') // prettier-ignore
                                },
                                {
                                  path: ':requestId/decline',
                                  lazy: () => import('./routes/admin._user-management.user-removal-requests.$requestId.decline/route') // prettier-ignore
                                }
                              ]
                            }
                          ]
                        },
                        {
                          path: 'user-groups',
                          loader: userGroupsLoader(queryClient),
                          lazy: () => import('./routes/admin.user-groups/route'),
                          children: [
                            {
                              path: ':userGroupId/edit',
                              loader: editUserGroupFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.user-groups.$userGroupId.edit/route') // prettier-ignore
                            },
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.user-groups.new/route')
                            }
                          ]
                        },
                        /** Program routes. */
                        {
                          path: 'programs',
                          loader: programsLoader(queryClient),
                          lazy: () => import('./routes/admin.programs/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.programs.new/route')
                            }
                          ]
                        },
                        {
                          path: 'programs/:programId',
                          loader: programManagementDataLoader(queryClient),
                          lazy: () => import('./routes/admin.programs_.$programId/route'),
                          children: [
                            {
                              path: '*',
                              loader: programStagesManagementDataLoader(queryClient),
                              lazy: () => import('./routes/admin.programs_.$programId.$/route'),
                              children: [
                                {
                                  // TODO: Remove wildcard once the challenge request modal is migrated.
                                  path: 'challenge-requests/:requestId/*',
                                  loader: programIdeationModuleRequestDataLoader(queryClient),
                                  lazy: () => import('./routes/admin.programs_.$programId.$.challenge-requests.$requestId/route') // prettier-ignore
                                },
                                {
                                  // TODO: Consider using `dispatcher?moduleId=123` instead.
                                  path: 'modules/:moduleId/dispatcher',
                                  loader: editProgramModuleDispatcherFormDataLoader(queryClient),
                                  lazy: () => import('./routes/admin.programs_.$programId.$.modules.$moduleId.dispatcher/route') // prettier-ignore
                                },
                                {
                                  // TODO: Consider using modules/:moduleId instead.
                                  path: 'modules/:moduleId/manage',
                                  lazy: () => import('./routes/admin.programs_.$programId.$.modules.$moduleId.manage/route') // prettier-ignore
                                },
                                {
                                  path: 'modules/:moduleId/reopen',
                                  lazy: () => import('./routes/admin.programs_.$programId.$.modules.$moduleId.reopen/route') // prettier-ignore
                                },
                                {
                                  // TODO: Consider using swimlanes?moduleId=123 instead.
                                  path: 'modules/:moduleId/swimlanes',
                                  lazy: () => import('./routes/admin.programs_.$programId.$.modules.$moduleId.swimlanes/route') // prettier-ignore
                                }
                              ]
                            },
                            {
                              path: 'role-assignments',
                              loader: programRoleAssignmentsLoader(queryClient),
                              lazy: () => import('./routes/admin.programs_.$programId.role-assignments/route'), // prettier-ignore
                              children: [
                                {
                                  path: 'new',
                                  loader: addProgramRoleAssignmentFormDataLoader(queryClient),
                                  lazy: () => import('./routes/admin.programs_.$programId.role-assignments.new/route') // prettier-ignore
                                }
                              ]
                            }
                          ]
                        },
                        /** Program template routes. */
                        {
                          path: 'program-templates',
                          loader: templateProgramsLoader(queryClient),
                          lazy: () => import('./routes/admin.program-templates/route'),
                          children: [
                            {
                              path: 'new',
                              lazy: () => import('./routes/admin.program-templates.new/route')
                            }
                          ]
                        },
                        {
                          path: 'program-templates/:templateProgramId',
                          loader: templateProgramManagementDataLoader(queryClient),
                          lazy: () => import('./routes/admin.program-templates_.$templateProgramId/route'), // prettier-ignore
                          children: [
                            {
                              path: 'edit',
                              loader: editTemplateProgramFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.program-templates_.$templateProgramId.edit/route') // prettier-ignore
                            },
                            {
                              // TODO: Consider using `dispatcher?moduleId=123` instead.
                              path: 'modules/:moduleId/dispatcher',
                              loader:
                                editTemplateProgramModuleDispatcherFormDataLoader(queryClient),
                              lazy: () => import('./routes/admin.program-templates_.$templateProgramId.modules.$moduleId.dispatcher/route') // prettier-ignore
                            },
                            {
                              // TODO: Consider using modules/:moduleId instead.
                              path: 'modules/:moduleId/manage',
                              lazy: () => import('./routes/admin.program-templates_.$templateProgramId.modules.$moduleId.manage/route') // prettier-ignore
                            },
                            {
                              // TODO: Consider using swimlanes?moduleId=123 instead.
                              path: 'modules/:moduleId/swimlanes',
                              lazy: () => import('./routes/admin.program-templates_.$templateProgramId.modules.$moduleId.swimlanes/route') // prettier-ignore
                            }
                          ]
                        }
                      ]
                    },
                    {
                      path: 'debug',
                      errorElement: <RouteErrorAlert />,
                      children: [
                        {
                          path: 'sandbox',
                          lazy: () => import('./routes/debug.sandbox/route')
                        },
                        {
                          path: 'graphql',
                          lazy: () => import('./routes/debug.graphql/route')
                        },
                        {
                          path: 'settings',
                          lazy: () => import('./routes/debug.settings/route')
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]
        }
      ]
    }
  ]);
}
