import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { UserFilterValues } from '@/features/user-management/components/UserFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, userKeys } from '../utils';
import { GetUsers, type GetUsersQueryVariables } from './graphql/GetUsers.gql';

type QueryVariables = Pick<GetUsersQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<UserFilterValues>;
};

export function getUsersQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: userKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetUsers, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'LAST_NAME_ASC',
  filter: { userStatuses: ['ACTIVE'] }
};

export function usersLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getUsersQueryOptions(defaultVariables));
}

export function parseVariables(variables: QueryVariables): GetUsersQueryVariables {
  const {
    filter: { invitationStatuses, searchText, userStatuses },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetUsersQueryVariables['filter']> = {};

  if (userStatuses?.length === 1) {
    if (userStatuses.at(0) === 'ACTIVE') {
      filter.isActive = true;
    } else if (userStatuses.at(0) === 'INACTIVE') {
      filter.isActive = false;
    }
  }

  // If all 3 are included we're omitting the filter since it serves no purpose.
  if (invitationStatuses?.length && invitationStatuses.length < 3) {
    filter.inviteStatus = invitationStatuses;
  }

  const _searchText = searchText?.trim();

  if (_searchText) {
    filter.searchText = _searchText;
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
