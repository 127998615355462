import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '../utils';
import { GetTemplateProgramManagementData } from './graphql/GetTemplateProgramManagementData.gql';

export function getTemplateProgramManagementDataQueryOptions(params: Params<'templateProgramId'>) {
  return queryOptions({
    queryKey: programManagementKeys.templateProgram(getRouteParam(params.templateProgramId)),
    queryFn: ({ queryKey: [{ templateProgramId }] }) =>
      fetchDocument(GetTemplateProgramManagementData, { templateProgramId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function templateProgramManagementDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) =>
    client.ensureQueryData(getTemplateProgramManagementDataQueryOptions(params));
}
