import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { getIdeationModuleRequestDataQueryOptions } from '@/features/ideation-module-requests/views/IdeationModuleRequestModal/queries';
import { getRouteParam } from '@/utils/routing';

export function programIdeationModuleRequestDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }: { params: Params<'programId' | 'requestId'> }) =>
    client.ensureQueryData(
      getIdeationModuleRequestDataQueryOptions({
        programId: getRouteParam(params.programId),
        requestId: getRouteParam(params.requestId)
      })
    );
}
