import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '../utils';
import { GetAddProgramRoleAssignmentFormData } from './graphql/GetAddProgramRoleAssignmentFormData.gql';

export function getAddProgramRoleAssignmentFormDataQueryOptions(params: Params<'programId'>) {
  return queryOptions({
    queryKey: programManagementKeys.programRoleAssignment_add(getRouteParam(params.programId)),
    queryFn: ({ queryKey: [{ programId }] }) =>
      fetchDocument(GetAddProgramRoleAssignmentFormData, { programId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function addProgramRoleAssignmentFormDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }: { params: Params<'programId'> }) =>
    client.ensureQueryData(getAddProgramRoleAssignmentFormDataQueryOptions(params));
}
