import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { brandingSettingsKeys, LOADER_STALE_TIME, queryOptions } from '../utils';
import { GetBrandingSettings } from './graphql/GetBrandingSettings.gql';

export function getBrandingSettingsQueryOptions() {
  return queryOptions({
    queryKey: brandingSettingsKeys.all,
    queryFn: () => fetchDocument(GetBrandingSettings),
    staleTime: LOADER_STALE_TIME
  });
}

export function BrandingSettingsLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getBrandingSettingsQueryOptions());
}
