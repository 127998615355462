import type { LoaderFunction, Params } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import { fetchDocument } from '@/utils/api/fetcher';
import { getRouteParam } from '@/utils/routing';
import { LOADER_STALE_TIME, programManagementKeys, queryOptions } from '../utils';
import { GetProgramStagesManagementData } from './graphql/GetProgramStagesManagementData.gql';

export function getProgramStagesManagementDataQueryOptions(params: Params<'programId'>) {
  return queryOptions({
    queryKey: programManagementKeys.programStagesList(getRouteParam(params.programId)),
    queryFn: ({ queryKey: [{ programId }] }) =>
      fetchDocument(GetProgramStagesManagementData, { programId }),
    staleTime: LOADER_STALE_TIME
  });
}

export function programStagesManagementDataLoader(client: QueryClient): LoaderFunction {
  return ({ params }) => client.ensureQueryData(getProgramStagesManagementDataQueryOptions(params));
}
