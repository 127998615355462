import type { LoaderFunction } from 'react-router-dom';
import type { QueryClient } from '@tanstack/react-query';
import type { SliceTemplateFilterValues } from '@/features/slice-templates/components/SliceTemplateFilterList';
import { fetchDocument } from '@/utils/api/fetcher';
import { LOADER_STALE_TIME, queryOptions, sliceTemplateKeys } from '../utils';
import {
  GetSliceTemplates,
  type GetSliceTemplatesQueryVariables
} from './graphql/GetSliceTemplates.gql';

type QueryVariables = Pick<GetSliceTemplatesQueryVariables, 'pagination' | 'sortOrder'> & {
  filter: Partial<SliceTemplateFilterValues>;
};

export function getSliceTemplatesQueryOptions(variables: QueryVariables) {
  return queryOptions({
    queryKey: sliceTemplateKeys.list(parseVariables(variables)),
    queryFn: ({ queryKey: [params] }) => fetchDocument(GetSliceTemplates, params.variables),
    staleTime: LOADER_STALE_TIME
  });
}

export const defaultVariables: QueryVariables = {
  pagination: { page: 1, size: 25 },
  sortOrder: 'NAME_ASC',
  filter: { statuses: ['NOT_ARCHIVED'] }
};

export function sliceTemplatesLoader(client: QueryClient): LoaderFunction {
  return () => client.ensureQueryData(getSliceTemplatesQueryOptions(defaultVariables));
}

function parseVariables(variables: QueryVariables): GetSliceTemplatesQueryVariables {
  const {
    filter: { creators, types, searchText, statuses },
    pagination: { page, size },
    sortOrder
  } = variables;

  const filter: NonNullable<GetSliceTemplatesQueryVariables['filter']> = {};

  if (statuses?.length) {
    if (statuses.includes('NOT_ARCHIVED') && !statuses.includes('ARCHIVED')) {
      filter.isArchived = false;
    } else if (statuses.includes('ARCHIVED') && !statuses.includes('NOT_ARCHIVED')) {
      filter.isArchived = true;
    }

    if (statuses.includes('NOT_PUBLISHED') && !statuses.includes('PUBLISHED')) {
      filter.isPublished = false;
    } else if (statuses.includes('PUBLISHED') && !statuses.includes('NOT_PUBLISHED')) {
      filter.isPublished = true;
    }
  }

  if (types?.length) {
    filter.types = types;
  }

  if (creators?.length) {
    filter.creatorIds = creators.map(c => c.id);
  }

  if (searchText?.trim()) {
    filter.searchText = searchText.trim();
  }

  return {
    pagination: { page, size },
    sortOrder,
    filter: Object.keys(filter).length ? filter : undefined
  };
}
